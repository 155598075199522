<template>
  <app-modal
    :title="$t('modal.media_upload_header')"
    modal-size="modal-lg"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        @click="uploadFiles"
        type="button"
        :class="`btn btn-green ${confirmButtonDisabled ? 'btn-green--disabled' : ''}`"
        data-dismiss="modal"
        :disabled="confirmButtonDisabled"
        data-test="complete_upload"
      >
        <i class="fa fa-check"></i> {{ $t('modal.done') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <div v-if="uploading" class="m-t-50 m-b-50">
        <h5 class="text-center">{{ `${$t('modal.uploading')} ${uploadingImageCount} / ${count}` }}</h5>
        <app-progressbar></app-progressbar>
      </div>
      <div class="m-b-40" v-if="loading">
        <h5 class="text-center">{{ `${$t('modal.reading')} ${loadingImageCount} / ${count}` }}</h5>
        <div class="progress">
          <div
            class="progress-bar bg-success wow animated progress-animated"
            style="width: 65%; height:6px;"
            role="progressbar"
          >
            <span class="sr-only">60% {{ $t('dam.complete') }}</span>
          </div>
        </div>
      </div>
      <div v-if="uploading === false">
        <div v-for="(uploadedFile, index) in uploadedFiles" :key="`item-${index}`">
          <div class="row">
            <div class="col-3">
              <img
                :src="uploadedFile.image"
                width="340"
                class="m-b-10"
              /><br>
              <app-dam-image-check-dimensions
                :file="uploadedFile.image"
              >
              </app-dam-image-check-dimensions>
            </div>
            <div class="col-8 text-left">
              <app-textarea
                v-model="uploadedFile.headline"
                id="asset_metadata_headline"
                :label="$t('dam.headline')"
                :rows="2"
              >
                <app-tooltip
                  :title="$t('dam.headline_seo_info')"
                  icon="fab fa-google"
                  customInfoClass="seo"
                ></app-tooltip>
              </app-textarea>
              <app-input
                v-model="uploadedFile.byLine"
                id="asset_metadata_byLine"
                :label="$t('dam.by_line')"
              >
              </app-input>
              <app-input
                v-model="uploadedFile.keywords"
                id="asset_metadata_keywords"
                :label="$t('dam.keywords')"
              >
              </app-input>
              <app-multi-select
                v-model="uploadedFile.restrictedSites"
                :options="restrictedSites"
                :placeholder="$t('dam.filter.restricted_sites_placeholder')"
                label="title"
                track-by="id"
                id="asset_restrictedSites"
                data-attr="asset_restrictedSites"
              >
              </app-multi-select>
            </div>
            <div class="col-1 text-left">
              <button
                @click="removeAsset(index)"
                class="btn btn-sm btn-danger m-t-30 m-l-20"
                title="Remove"
              >
                <i class="fas fa-window-close"> </i>
              </button>
            </div>
          </div>
          <hr v-if="index + 1 < uploadedFiles.length">
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Progressbar from '../Progressbar'
import DamApi from '../../api/dam'
import MediaService from '../../services/media/MediaService'
import DamImageCheckDimensions from './DamImageCheckDimensions'
import { SOURCE_DAM_IMAGE } from '@/model/ValueObject/DamUploadSources'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'
import MultiSelect from '../form/select/MultiSelect'
import Tooltip from '../tooltip/Tooltip'

export default {
  name: 'DamUploadModal',
  props: {
    files: {
      type: Array
    },
    count: {
      type: Number
    }
  },
  data () {
    return {
      uploading: false,
      loading: true,
      uploadedFiles: [],
      loadingImageCount: 0,
      uploadingImageCount: 0,
      timeoutError: false,
      uploadedAssets: []
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appProgressbar: Progressbar,
    appDamImageCheckDimensions: DamImageCheckDimensions,
    appMultiSelect: MultiSelect,
    appTooltip: Tooltip
  },
  computed: {
    confirmButtonDisabled () {
      return this.uploading || this.loading
    },
    restrictedSites () {
      return this.$store.getters['user/currentUser'].sites.map(function (currentUserSiteId) {
        return this.$store.getters['site/all'].find(enabledSite => enabledSite.id === currentUserSiteId)
      }.bind(this))
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async load () {
      this.uploadedFiles = this.files
    },
    async uploadFiles () {
      this.timeoutError = false
      this.uploading = true
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < this.uploadedFiles.length; i += chunkSize) {
        this.uploadingImageCount = i
        const chunkUploadedFiles = this.uploadedFiles.slice(i, i + chunkSize)
        const promises = chunkUploadedFiles.map(this.uploadFile)
        await Promise.all(promises)
      }
      this.$emit('set-media', this.uploadedAssets)
      this.close()
    },
    async uploadFile (file) {
      const bodyFormData = new FormData()
      bodyFormData.set('image', MediaService.dataUriToBlob(file.image), file.name)
      bodyFormData.set('headline', file.headline)
      bodyFormData.set('byLine', file.byLine)
      bodyFormData.set('keywords', file.keywords)
      if (file.dateTimeOriginal) {
        bodyFormData.set('dateTimeOriginal', file.dateTimeOriginal)
      }
      if (file.restrictedSites) {
        bodyFormData.set('transmissionReference', file.restrictedSites.map(function (restrictedSite) {
          return restrictedSite.name
        }).join(','))
      }
      bodyFormData.set('uploadSource', SOURCE_DAM_IMAGE)
      return DamApi(Config.dam.apiUploadTimeout).post('/image', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.uploadedAssets.push(response.data)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.upload_timeout_error'), '', -1)
          }
          console.log(error)
        })
    },
    removeAsset (index) {
      this.uploadedFiles = this.uploadedFiles.filter((media, mediaIndex) => mediaIndex !== index)
    }
  },
  watch: {
    uploadedFiles () {
      this.loadingImageCount = this.uploadedFiles.length
      if (this.loadingImageCount >= this.count) {
        this.loading = false
      }
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
.btn-green--disabled, .btn-green--disabled:hover {
  color: lighten(#465674, 20%);
  background-color: lighten(#D1DBE4, 5%);
  cursor: default !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
</style>
