<template>
  <span>
    <p
      v-if="size > maxFileSize"
      class="alert alert-warning"
    >
      <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_max_size') }}
    </p>
    <p
      v-if="dimensions.width < minFileWidth"
      class="alert alert-warning"
    >
      <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_low_quality') }}
      {{ dimensions.width }}x{{ dimensions.height }} px
    </p>
  </span>
</template>

<script>
const MAX_FILE_SIZE = 99
const MIN_FILE_WIDTH = 600

export default {
  name: 'DamImageCheckDimensions',
  props: {
    file: {
      type: String
    }
  },
  data () {
    return {
      dimensions: {
        width: 9999,
        height: 9999
      },
      size: 0,
      maxFileSize: MAX_FILE_SIZE,
      minFileWidth: MIN_FILE_WIDTH
    }
  },
  methods: {
    async checkImageDimensions (file) {
      this.dimensions = await this.getImageDimensions(file)
    },
    getImageDimensions (file) {
      return new Promise((resolve, reject) => {
        var i = new Image()
        i.onload = function () {
          resolve({
            width: i.width,
            height: i.height
          })
        }
        i.src = file
      })
    },
    getSize (file) {
      this.size = (file.length / 1048576) / 1.303
    }
  },
  created () {
    this.checkImageDimensions(this.file)
    this.getSize(this.file)
  }
}
</script>

<style lang="scss" scoped>
  .alert-warning {
    font-size: 12px;
  }
</style>
