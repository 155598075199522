<template>
  <div>
    <div class="progress m-t-30">
      <div class="progress-bar bg-info wow animated progress-animated" style="width: 50%; height:6px;"
           role="progressbar">
        <span class="sr-only">60% Complete</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progressbar'
}
</script>
